.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
}

#navbar {
  text-align: center;
  list-style-type: none;
}

#navbar li {
  display: inline;
  padding: 8px;
  background-color: #dddddd;

}

#map {
  height: 400px;
  width: 100%;  
}

table, th, td {
  border: 1px solid black;
}

/**** Article links ******/

.article {
  height: 300px;
  width: 300px;
  display: block;
  object-fit: contain;
}

.articleImage {
  display: block;
  height: 300px;
  margin-top: 25px;
  /* width: 100%; */
  /* object-fit: contain; */
}

.articlePreview {
  width: 100%;    
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-top: solid red;
  border-width: 1px;
  font-family: Paralucent;
}

.articlePreview2 {
  width: 100%;    
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-top: solid red;
  border-width: 1px;
  font-family: Paralucent;
}

.articleTitle { 
  font-size: 20px;
  font-weight: 900;
  margin: 10px;
}

/**** Article links ******/

/* Nav Bar ****************/

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}
.container {
  width: 100%;
	padding-right: 25px;
	padding-left: 25px;
  padding-bottom: 25px;
	margin-right: auto;
	margin-left: auto
}
img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ff5800!important;
}

.navBar .col-md-3 {
  z-index: 7;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}

ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}

.menu-item {
  line-height: 23px;
}

li {
  list-style-type: none;
}

.menu-item a {
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #545050;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 20%;
  display: inline-block;
  float: left;
}
.header__middle__menus {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 1px dashed lavender;
  position: relative;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #07a8ff !important;
}
.menu-item .sub__menus li:hover a{
  color: #07a8ff !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}

@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #d8f1ff;
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}

/************ Nav Bar ***********/
 
span {
  padding: 10px;
}

a {
  /* text-align: center; */
  display:block;
  color: black;
}

/******** Icons ************/
/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 20%;
}

.container {
  grid-template-columns: repeat(2, 1fr);
}

/* split */

@media (min-width: 900px) {
  * {
    box-sizing: border-box;
  }

  /* Create two equal columns that floats next to each other */  
  .column-main {
    float: left;
    width: 50%;
    padding: 10px;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media only screen and (max-width: 900px) {
  .column-main {
    width: 100%;
    padding: 10px;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  * {
    box-sizing: content-box;
  } 
}

/* split */

.articleContainer {
  margin: 50px;
  /* padding: 20px; */
}

.tag {  
  background: #ececec;
  border-style: solid;  
  border-width: 1px;
  display: inline;
  padding: 5px;
  text-align: center;
}


/* new */
.link {
  border-style: solid;
}

.icon {
  height: 50px;
  width: 50px;
}

/* <!-- HTML !--> */

/* CSS */
.button-49,
.button-49:after {
  width: 150px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-49:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.button-49:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (min-width: 768px) {
  .button-49,
  .button-49:after {
    width: 200px;
    height: 86px;
    line-height: 88px;
  }
}

a {
  text-decoration:none
}