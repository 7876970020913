h3,
h4 {
    text-align: center;
}

.health-container {
    background-color: #f1ae9d;
}

.finance-container {
    background-color: #c5f4c1;
}

.vanity-container {
    background-color: #b3eaf7;
}

.stat {
    font-weight: bold;
}

.stat-neutral {
    font-size: 25px;
    color: blue;
}

.stat-bad {
    font-size: 25px;
    color: red;
    font-weight: bold;
}

.stat-good {
    font-size: 25px;
    color: green;
}

.stat-good-subset {
    font-size: 25px;
    color: green;
    margin-left: 20px;
}

.small {
    font-size: 1rem;
    font-family: serif;
    text-align: center;
    color: grey;

    /* Text Shadow Property */
    text-shadow: -1px -1px 0 grey, 1px -1px 0 grey, 1px 1px 0 grey,
        1px 1px 0 grey;
}

.container {
    margin: 10px auto;
    width: 600px;
    text-align: center;
}

/* money progress */

.ageProgress {
    height: 24px;
    width: 20%
}

/* h1 {
    text-align: left;
} */

/* body {
    padding: 50px;
} */

.progress-bg {
    margin: 0 auto;
    width: 90%;
    height: 78px;
    border-radius: 10px;
    text-align: center;
    -moz-box-shadow: inset 0 0 10px #ccc;
    -webkit-box-shadow: inset 0 0 10px #ccc;
    box-shadow: inset 0 0 10px #ccc;
}

.progress-bar {
    height: 78px;
    border-radius: 10px;
    float: left;
    width: 50%;
    /* fallback */
    background-color: #1c314a;

    /* Safari 4-5, Chrome 1-9 */
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#1c314a), to(#27425f));

    /* Safari 5.1, Chrome 10+ */
    background: -webkit-linear-gradient(top, #1c314a, #27425f);

    /* Firefox 3.6+ */
    background: -moz-linear-gradient(top, #1c314a, #27425f);

    /* IE 10 */
    background: -ms-linear-gradient(top, #1c314a, #27425f);

    /* Opera 11.10+ */
    background: -o-linear-gradient(top, #1c314a, #27425f);
}

.progress-bg h3.goal,
.progress-bg h3.raised {
    font-family: Arial, sans-serif;
    font-size: 1em;
    font-weight: 600;
    line-height: 78px;
    margin: 0;
    padding: 0;
    text-align: center;
    display: inline;
}


.progress-bg h3.raised {
    color: #fff;
    margin: 14px 25px 0 50px;
    padding: 0 25px 0 0;
}

.progress-bg h3.goal {
    color: #b2b2b2;
    text-align: center;
}

body .progress-bg h3.raised {
    -webkit-animation: fadein 4s;
    /* Safari and Chrome */
    -moz-animation: fadein 4s;
    /* Firefox */
    -ms-animation: fadein 4s;
    /* Internet Explorer */
    -o-animation: fadein 4s;
    /* Opera */
    animation: fadein 4s;
}

/* money progress */

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari and Chrome */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

​

/* Opera */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

​ .progress-bg h3.goal {
    float: right;
    display: inline;
    padding: 0 25px 0 0;
    text-align: center;
}

body .progress-bg div {
    -webkit-animation: progress-bar 2s ease forwards;
    -moz-animation: progress-bar 2s ease forwards;
    -o-animation: progress-bar 2s ease forwards;
    animation: progress-bar 2s ease forwards;
}

@-webkit-keyframes progress-bar {
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
}

@-moz-keyframes progress-bar {
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
}

@-o-keyframes progress-bar {
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
}

@keyframes progress-bar {
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
}

/* money progress */

/* tree view */

.tree {
    --spacing: 1.5rem;
    --radius: 10px;
}

.tree li {
    display: block;
    position: relative;
    padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
    margin-left: calc(var(--radius) - var(--spacing));
    padding-left: 0;
}

.tree ul li {
    border-left: 2px solid #ddd;
}

.tree ul li:last-child {
    border-color: transparent;
}

.tree ul li::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--spacing) / -2);
    left: -2px;
    width: calc(var(--spacing) + 2px);
    height: calc(var(--spacing) + 1px);
    border: solid #ddd;
    border-width: 0 0 2px 2px;
}

.tree summary {
    display: block;
    cursor: pointer;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
    display: none;
}

.tree summary:focus {
    outline: none;
}

.tree summary:focus-visible {
    outline: 1px dotted #000;
}

.tree li::after,
.tree summary::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--spacing) / 2 - var(--radius));
    left: calc(var(--spacing) - var(--radius) - 1px);
    width: calc(2 * var(--radius));
    height: calc(2 * var(--radius));
    border-radius: 50%;
    background: #ddd;
}

.tree summary::before {
    z-index: 1;
    background: #696 url('images/expand-collapse.svg') 0 0;
}

.tree details[open]>summary::before {
    background-position: calc(-2 * var(--radius)) 0;
}

/* tree view */

/* warning banner */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

body {
    font-family: 'Open Sans', sans-serif;
    line-height: normal;
    background: #e9f0f4;
    padding: 0;
    margin: 0;
}

.content {
    padding: 0;
    margin: 5% 15%;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert h4 {
    margin-top: 0;
    color: inherit;
}

.alert .alert-link {
    font-weight: bold;
}

.alert>p,
.alert>ul {
    margin-bottom: 0;
}

.alert>p+p {
    margin-top: 5px;
}

.alert-dismissable {
    padding-right: 35px;
}

.alert-dismissable .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
}

.alert-success hr {
    border-top-color: #c9e2b3;
}

.alert-success .alert-link {
    color: #2b542c;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

.alert-info hr {
    border-top-color: #a6e1ec;
}

.alert-info .alert-link {
    color: #245269;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.alert-warning hr {
    border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
    color: #66512c;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

.alert-danger hr {
    border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
    color: #843534;
}

.alert {
    border-radius: 0;
    -webkit-border-radius: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11);
}

.alert .sign {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
    text-align: center;
    width: 25px;
    display: inline-block;
}

.alert-success {
    background-color: #dbf6d3;
    border-color: #aed4a5;
    color: #569745;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #98cce6;
    color: #3a87ad;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #f1daab;
    color: #c09853;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #e0b1b8;
    color: #b94a48;
}

.alert-white {
    background-image: linear-gradient(to bottom, #FFFFFF, #F9F9F9);
    border-top-color: #d8d8d8;
    border-bottom-color: #bdbdbd;
    border-left-color: #cacaca;
    border-right-color: #cacaca;
    color: #404040;
    padding-left: 61px;
    position: relative;
}

.alert-white .icon {
    text-align: center;
    width: 45px;
    height: 100%;
    position: absolute;
    top: -1px;
    left: -1px;
    border: 1px solid #bdbdbd;
}

.alert-white .icon:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid #bdbdbd;
    position: absolute;
    border-left: 0;
    border-bottom: 0;
    top: 50%;
    right: -6px;
    margin-top: -5px;
    background: #fff;
}

.alert-white.rounded {
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.alert-white.rounded .icon {
    border-radius: 3px 0 0 3px;
    -webkit-border-radius: 3px 0 0 3px;
}

.alert-white .icon i {
    font-size: 20px;
    color: #FFF;
    left: 12px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
}

.alert-white.alert-danger-avoided .icon,
.alert-white.alert-danger-avoided .icon:after {
    /* border-color: #ca452e;
    background: #da4932; */
    border-color: black;
    background: green;
}

.alert-white.alert-danger .icon,
.alert-white.alert-danger .icon:after {
    border-color: #ca452e;
    background: #da4932;
    /* border-color: grey;
    background: grey; */
}

.alert-white.alert-info .icon,
.alert-white.alert-info .icon:after {
    border-color: rgb(18, 186, 18);
    background: rgb(214, 208, 208);
}

.alert-white.alert-warning .icon,
.alert-white.alert-warning .icon:after {
    border-color: #d68000;
    background: #fc9700;
}

.alert-white.alert-success .icon,
.alert-white.alert-success .icon:after {
    border-color: #54a754;
    background: #60c060;
}

/* warning banner */

/* gauge */

.gauge-wrapper {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    padding: 20px 15px 15px;
}

.gauge {
    background: #e7e7e7;
    box-shadow: 0 -3px 6px 2px rgba(0, 0, 0, 0.50);
    width: 200px;
    height: 100px;
    border-radius: 100px 100px 0 0 !important;
    position: relative;
    overflow: hidden;
}

.gauge.min-scaled {
    transform: scale(0.5);
}

.gauge-center {
    content: '';
    color: #fff;
    width: 60%;
    height: 60%;
    background: #15222E;
    border-radius: 100px 100px 0 0 !important;
    position: absolute;
    box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
    right: 21%;
    bottom: 0;
    color: #fff;
    z-index: 10;
}

.gauge-center .label,
.gauge-center .number {
    display: block;
    width: 100%;
    text-align: center;
    border: 0 !important;
}

.gauge-center .label {
    font-size: 0.75em;
    opacity: 0.6;
    margin: 1.1em 0 0.3em 0;
}

.gauge-center .number {
    font-size: 1.2em;
}

.needle {
    width: 80px;
    height: 7px;
    background: #15222E;
    border-bottom-left-radius: 100% !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 100% !important;
    border-top-right-radius: 5px !important;
    position: absolute;
    bottom: -2px;
    left: 20px;
    transform-origin: 100% 4px;
    transform: rotate(115deg);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
    display: none;
    z-index: 9;
}

.needle-danger {
    width: 80px;
    height: 7px;
    background: #15222E;
    border-bottom-left-radius: 100% !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 100% !important;
    border-top-right-radius: 5px !important;
    position: absolute;
    bottom: -2px;
    left: 20px;
    transform-origin: 100% 4px;
    transform: rotate(65deg);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
    display: none;
    z-index: 9;
}

.four.rischio1 .needle {
    animation: fourspeed1 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio2 .needle {
    animation: fourspeed2 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio3 .needle {
    animation: fourspeed3 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio4 .needle {
    animation: fourspeed4 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio1 .needle-danger {
    animation: fourspeed1 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio2 .needle-danger {
    animation: fourspeed2 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio3 .needle-danger {
    animation: fourspeed3 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio4 .needle-danger {
    animation: fourspeed4 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.slice-colors {
    height: 100%;
}

.slice-colors .st {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border: 50px solid transparent;
}

.four .slice-colors .st.slice-item:nth-child(2) {
    border-top: 50px #f1c40f solid;
    border-right: 50px #f1c40f solid;
    background-color: #e67e22;
}

.four .slice-colors .st.slice-item:nth-child(4) {
    left: 50%;
    border-bottom: 50px #E84C3D solid;
    border-right: 50px #E84C3D solid;
    background-color: #1eaa59;
}


@-webkit-keyframes fourspeed1 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(16deg);
    }
}

@-webkit-keyframes fourspeed2 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(65deg);
    }
}

@-webkit-keyframes fourspeed3 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(115deg);
    }
}

@-webkit-keyframes fourspeed4 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(164deg);
    }
}

/* gauge */

/* gauge legend */

.gauge-legend-good {
    color: #1eaa59;
    ;
    font-weight: bold;
}

.gauge-legend-risk {
    color: #f1c40f;
    ;
    font-weight: bold;
}

.gauge-legend-bad {
    color: #E84C3D;
    ;
    font-weight: bold;
}

/* gauge legend */

/* tool tip */

.tooltip {
    text-align: center;
    font-weight: bold;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted rgb(58, 57, 57);
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(49, 47, 47);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 11;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* tool tip */

/* tag */

.tag {
    display: inline-block;
    border-radius: 3px;
    padding: .2em .5em .3em;
    border-radius: 2px;
    background: var(--tag-bg);
    color: var(--text-color);
    font-weight: 600;
    margin: .25em .1em
}

.tag-python {
    color: #ffda5d;
    background: #3879ab
}

.tag-todo,
.tag-mvp {
    color: #fff;
    background-image: linear-gradient(90deg, #ff8901, #db1d5f);
    font-weight: 700
}

.tag-lg {
    font-size: .8em;
    border-radius: 4px
}

/* tag */

/* progress tracker */

.progress-meter {
    padding: 0;
}

ol.progress-meter {
    padding-bottom: 9.5px;
    list-style-type: none;
}

ol.progress-meter li {
    display: inline-block;
    text-align: center;
    text-indent: -19px;
    height: 36px;
    width: 24.99%;
    font-size: 12px;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

ol.progress-meter li:before {
    position: relative;
    float: left;
    text-indent: 0;
    left: -webkit-calc(50% - 9.5px);
    left: -moz-calc(50% - 9.5px);
    left: -ms-calc(50% - 9.5px);
    left: -o-calc(50% - 9.5px);
    left: calc(50% - 9.5px);
}

ol.progress-meter li.done {
    font-size: 12px;
}

ol.progress-meter li.done:before {
    content: "\2713";
    height: 19px;
    width: 19px;
    line-height: 21.85px;
    bottom: -28.175px;
    border: none;
    border-radius: 19px;
}

ol.progress-meter li.todo {
    font-size: 12px;
}

ol.progress-meter li.todo:before {
    content: "\2B24";
    font-size: 17.1px;
    bottom: -26.95px;
    line-height: 18.05px;
}

ol.progress-meter li.done {
    color: black;
    border-bottom-color: yellowgreen;
}

ol.progress-meter li.done:before {
    color: white;
    background-color: yellowgreen;
}

ol.progress-meter li.todo {
    color: silver;
    border-bottom-color: silver;
}

ol.progress-meter li.todo:before {
    color: silver;
}

/* progress tracker */

/* collaspsible */
.accordion>input[type="checkbox"] {
    position: absolute;
    left: -100vw;
}

.accordion .content {
    overflow-y: hidden;
    height: 0;
    transition: height 0.3s ease;
}

.accordion>input[type="checkbox"]:checked~.content {
    height: auto;
    overflow: visible;
}

.accordion label {
    display: block;
}

.accordion {
    margin-bottom: 1em;
}

.accordion>input[type="checkbox"]:checked~.content {
    padding: 15px;
    border: 1px solid #e8e8e8;
    border-top: 0;
}

.accordion .handle {
    margin: 0;
    font-size: 1.125em;
    line-height: 1.2em;
}

.accordion label {
    color: #333;
    cursor: pointer;
    font-weight: normal;
    padding: 15px;
    background: #e8e8e8;
}

.accordion label:hover,
.accordion label:focus {
    background: #d8d8d8;
}

.accordion .handle label:before {
    font-family: 'fontawesome';
    content: "\f054";
    display: inline-block;
    margin-right: 10px;
    font-size: .58em;
    line-height: 1.556em;
    vertical-align: middle;
}

.accordion>input[type="checkbox"]:checked~.handle label:before {
    content: "\f078";
}


/*
   Demo purposes only
  */
*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    padding: 40px;
}

a {
    color: #06c;
}

p {
    margin: 0 0 1em;
}

h1 {
    margin: 0 0 1.5em;
    font-weight: 600;
    font-size: 1.5em;
}

.accordion {
    max-width: 65em;
}

.accordion p:last-child {
    margin-bottom: 0;
}

/* collaspsible */

/* heart */

.container-heart {
    display: flex;
    justify-content: center;
    align-items: center;
}

.human-heart {
    margin: 5em;
    animation: .8s infinite beatHeart;
}

@keyframes beatHeart {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    40% {
        transform: scale(1);
    }

    60% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

/* heart */

/* biomarkers */

* {
    box-sizing: border-box;
    font-family: sans-serif;
}

:root {
    --default-animation-duration: 0.2s;
    --default-border-radius: 0.4rem;

    --hud-color: 0, 255, 200;
    --hud--focus-color: 255, 255, 255;
    --hud-opacity-primary: 1;
    --hud-opacity-secondary: 0.2;
}

html,
body {
    font-size: 20px;
    /* height: 100%; */
    margin: 0;
    padding: 0;
    width: 100%;
}

.gui-container {
    background-color: #2e2e2e;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
    width: 50rem;
}

/* biomarkers */

:root {
    --color-primary: #4B888C;
    --color-primary-light: #76B9BD;
    --color-primary-lightest: #D6EBEC;
    --color-secondary: #76BDA4;
}

/* *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
		font-family: 'Inter', sans-serif;
} */

/* html {
    font-size: 62.5%;
} */

/* body {
    font-weight: 400;
    color: #444;
    box-sizing: border-box;
    padding: 5rem;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #97e0c7, #4B888C);
} */

.stats-content {
    /* width: 100%; */
    background-color: #fff;
    box-shadow: 0 0 5rem rgba(0, 0, 0, .4);
    border-radius: 2rem;
    z-index: 5;
    padding: 3rem 5rem;

    font-weight: 400;
    color: #444;
    box-sizing: border-box;
    padding: 3rem;
    /* min-height: 70vh; */
    background: linear-gradient(to bottom right, #97e0c7, #4B888C);
}

section {
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: 0 0 3rem rgba(0, 0, 0, .3);
    border-radius: .8rem;
}

section>h2 {
    font-size: 2rem;
    letter-spacing: .2rem;
    font-weight: 400;
    color: var(--color-primary);
}

.greeting {
    margin-bottom: 4rem;
    font-size: 1.4rem;
}


main {
    display: grid;
    grid-template-columns: 16fr 5fr;
    grid-template-rows: auto auto;
    gap: 4rem;
}

.grid-area-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 2fr;
    gap: 4rem;
}

.grid-area-1-2 {
    grid-column: 2 / 3;
    grid-row: 1 / 3;

    display: flex;
    flex-direction: column;
    gap: 4rem;
}


.grid-area-2 {
    grid-column: 2 / 3;
    grid-row: 1 / 3;

    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.grid-area-3 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
}

.last-week {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
}


.distance {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}

.distance__section {
    position: relative;
}

.distance__section::after {
    content: '';
    height: 80%;
    width: 1.6rem;
    background-color: var(--color-primary-light);
    position: absolute;
    right: 0;
    top: 10%;
    border-radius: 1.2rem 0 0 1.2rem;
    box-shadow: inset .4rem .4rem 1rem rgba(0, 0, 0, .2);
}

.distance p {
    color: var(--color-primary);
    font-size: 2rem;
}

.distance .distance__section>h2 {
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0;
}

.distance svg {
    height: 5rem;
    color: var(--color-primary);
    margin-top: 2rem;
}

.recent {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.recent a {
    align-self: end;
}

.recent table {
    font-size: 1.6rem;
    width: calc(100% + 4rem);
    text-align: left;
    margin: 2rem -2rem .5rem -2rem;
    border: none;
}

.recent tr:nth-of-type(odd) {
    background-color: var(--color-primary-lightest);
}

.recent td {
    padding: .8rem .2rem;
}

.recent svg {
    width: 3rem;
    height: 3rem;
    background-color: #fff;
    border-radius: 50%;
    padding: .5rem;
    color: var(--color-primary);
    margin-left: 2rem;
}

.calories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;
}

.calories h2 {
    grid-column: 1 / 4;
}

.calories__number {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 600;
    color: var(--color-primary);
    margin: 1.5rem 0;
}

.calories__text {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 2rem;
}

.friend-acitvity {
    display: flex;
    flex-direction: column;
}

.friend-activity__activity {
    display: grid;
    grid-template-columns: 4rem 1fr;
    gap: 2rem;
    align-items: center;
    margin: 1rem 0;
}

.friend-acitvity__picture {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: var(--color-primary);
    overflow: hidden;
    position: relative;
}

.friend-acitvity__picture>div {
    background-color: #fff;
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: -.6rem;
    left: 1rem;
    border-radius: 50% 50% 0 0;
}

.friend-acitvity__picture>div::after {
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    bottom: 2.3rem;
    left: .4rem;
}

.friends__picture {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--color-primary);
    overflow: hidden;
    position: relative;
}

.friends__picture>div {
    background-color: #fff;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    bottom: -.6rem;
    left: .8rem;
    border-radius: 50% 50% 0 0;
}

.friends__picture>div::after {
    content: '';
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    bottom: 1.8rem;
    left: .4rem;
}

.friend-activity__name,
.friends__name {
    color: var(--color-primary);
    font-size: 1.8rem;
    font-weight: 400;
}

.friend-activity__msg,
.friends__msg {
    font-size: 1.4rem;
}

.friends__friend {
    display: grid;
    grid-template-columns: 4rem 1fr auto;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    margin: 1rem 0;
}

.challenges {
    display: grid;
    grid-template-rows: auto repeat(1.4rem, 2rem);
    gap: .8rem;
    padding-bottom: 2rem;
}

.challenges__bar {
    width: 100%;
    height: 1.4rem;
    border-radius: .7rem;
    background-color: #D9D9D9;
    position: relative;
}

.challenges__bar::after {
    content: '';
    position: absolute;
    height: 100%;
    border-radius: .7rem;
    background-color: var(--color-primary);
}

.challenges__bar--1::after {
    width: 20%;
}

.challenges__bar--2::after {
    width: 70%;
}

.challenges__bar--3::after {
    width: 45%;
}

.challenges__text {
    font-size: 1.4rem;
}

.personal-bests {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}

.personal-bests__best {
    background-color: var(--color-primary);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 2rem;
    position: relative;
}

.personal-bests__best::after {
    content: '';
    background-color: var(--color-primary-lightest);
    width: 80%;
    /* height: 1rem; */
    border-radius: 0 0 1rem 1rem;
    box-shadow: inset .4rem -.4rem 1rem rgba(0, 0, 0, .2);
    position: absolute;
    top: 0;    
    left: 10%;
}

.personal-bests__best h2 {
    color: #ffffff8c;
    text-align: center;
    letter-spacing: 0;
    font-size: 1.8rem;
}

.personal-bests__best p {
    font-size: 2rem;
}

.personal-bests__best svg {
    width: 4rem;
    height: 3rem;
}

.last-week {
    display: flex;
    flex-direction: column;
}

.last-week__chart {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
    margin-top: 2rem;
    padding-top: 4rem;
    border-bottom: 1px solid #757575;
    align-items: end;
}

.last-week__bar {
    width: 3.5rem;
    border-radius: .4rem .4rem 0 0;
    background-color: var(--color-primary);
    height: 20rem;
}

.last-week__bar--1 {
    justify-self: end;
    height: 17rem;
}

.last-week__bar--2 {
    height: 20rem;
}

.last-week__bar--3 {
    justify-self: end;
    background-color: var(--color-primary-light);
    height: 15rem;
}

.last-week__bar--4 {
    background-color: var(--color-primary-light);
    height: 12rem;
}

.last-week__bar--5 {
    justify-self: end;
    background-color: var(--color-secondary);
    height: 10rem;
}

.last-week__bar--6 {
    background-color: var(--color-secondary);
    height: 16rem;
}

.last-week__bar>p {
    font-size: 1.4rem;
    text-align: center;
    transform: translateY(-2rem);
}

.last-week__labels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    font-size: 1.8rem;
    margin: 2rem 0;
}

@media (max-width: 1500px) {
    body {
        padding: 0;
    }

    nav,
    .content {
        border-radius: 0;
    }
}

@media (max-width: 1250px) {
    main {
        grid-template-columns: 1fr;
    }

    .grid-area-2 {
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 950px) {
    .grid-area-1 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr auto;
    }

    .grid-area-1-2 {
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .last-week {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }

    .grid-area-3 {
        grid-template-rows: auto auto auto;
    }

    .challenges {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }

    .personal-bests {
        grid-column: 1 / 3;
    }
}

@media (max-width: 750px) {
    .grid-area-2 {
        display: flex;
        flex-direction: column;
    }

    .grid-area-1-2 {
        display: flex;
        flex-direction: column;
    }

    .all {
        grid-template-columns: 1fr;
    }

    nav {
        display: none;
    }

    .content {
        padding: 2rem 1rem;
    }
}

@media (max-width: 450px) {
    html {
        font-size: 50%;
    }
}

